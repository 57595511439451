.folder-actions {
  float: right;
}

.folder-actions .tide-action-dropdown-actions {
  border: none;
  box-shadow: var(--box-shadow-1);
  width: 208px;
}

.folder-actions__dropdown-button {
  visibility: hidden;
}

.folder-actions .tide-action-dropdown-actions .tide-action-dropdown-action:last-child {
  color: var(--color-s3-6);
}
