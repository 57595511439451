/* PLAYER STYLES */
.video-container {
  height: 70%;
  background: black;
  margin: 0px 40px;
}

.video-container-inner {
  margin: 0 auto;
}

.full {
  height: 100%;
}

.with-captions {
  display: flex;
  flex-direction: column;
}

#video-player-components-container {
  flex-grow: 1;
}

.drift-video .vjs-tech:focus {
  outline: none;
}

.drift-video .video-js .vjs-control-bar {
  display: flex !important;
  width: 100%;
  height: 40px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, transparent, #000000);
  background-color: transparent;
  padding: 0 10px;
}

.drift-video .video-js .vjs-control {
  outline: none;
}

.drift-video .video-js .vjs-play-control {
  height: 40px;
  width: 40px;
  font-size: 14px;
}

.drift-video .video-js .vjs-volume-panel {
  height: 40px;
  width: 40px;
  font-size: 14px;
}

.drift-video .video-js .vjs-volume-panel .vjs-mute-control.vjs-control {
  width: 40px;
}

.drift-video .vjs-volume-control .vjs-slider-horizontal,
.drift-video .vjs-volume-control .vjs-slider-horizontal .vjs-volume-level {
  height: 3px;
}

.drift-video .video-js .vjs-time-control {
  display: block;
  padding: 0px;
  font-family: var(--main-font-family);
  font-size: 14px;
}

.drift-video .video-js .vjs-icon-volume-high:before,
.drift-video .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 24px;
  line-height: 40px;
}

.drift-video .video-js .vjs-time-control.vjs-time-divider {
  min-width: 2px;
  padding: 0 1px;
}

.drift-video .video-js .vjs-remaining-time {
  display: none;
}

.drift-video .video-js .vjs-progress-control {
  position: absolute;
  bottom: 40px;
  /* The height of the ControlBar minus 4px. */
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  height: 50px;
  padding-top: 40px;
  margin: 0 20px;
  /* the height must be reduced from 30 to 10px in order to allow the buttons below (e.g. play) to be pushed */
}

.drift-video .video-js .vjs-progress-control .vjs-progress-holder {
  /* needed to have a real 100% width display. */
  margin-left: 0px;
  margin-right: 0px;
}

.drift-video .video-js .vjs-play-progress.vjs-slider-bar {
  background-color: var(--main-blue);
}
.drift-video .video-js .vjs-play-progress .vjs-time-tooltip {
  font-family: var(--main-font-family);
}

.drift-video .video-js .vjs-control-bar .vjs-progress-control .vjs-play-progress:before {
  content: '';
  width: 15px;
  height: 15px;
  top: -0.7em;
  border-radius: 7.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  border: solid 4.5px #ffffff;
  transition-property: top;
  transition-duration: 0.25s;
  background-color: var(--main-blue);
}

.drift-video .video-js .vjs-control-bar .vjs-progress-control:hover .vjs-play-progress:before {
  top: -0.333333333333333em;
}

.drift-video .video-js .vjs-control-bar .vjs-fullscreen-control {
  position: absolute;
  right: 10px;
  font-size: 14px;
  width: 40px;
}

.drift-video .video-js .vjs-control-bar .vjs-playback-rate {
  position: absolute;
  right: 50px;
  font-family: var(--main-font-family);
}
.drift-video .video-js .vjs-control-bar .vjs-playback-rate {
  outline: none;
  z-index: 1;
}

.drift-video .video-js .vjs-control-bar .vjs-playback-rate .vjs-menu {
  width: 5em;
  left: -10%;
}

.drift-video .video-js .vjs-control-bar .vjs-playback-rate .vjs-playback-rate-value {
  font-size: 14px;
  line-height: 40px;
}

.drift-video .video-js .vjs-control-bar .vjs-subs-caps-button {
  position: absolute;
  right: 45px;
  padding: 0;
  margin-top: 2px;
  font-size: 11px;
}

.video-js .vjs-text-track-display > div > div > div {
  font-size: 70% !important;
  line-height: 22px !important;
  padding: 0.5% !important;
  background: rgba(0, 0, 0, 0.75) !important;
  font-family: var(--main-font-family) !important;
  display: inline-block !important;
}

.drift-video .video-js .permanent-mute-tooltip {
  position: absolute;
  bottom: 36px;
  left: 68px;
}

.drift-video .video-js .permanent-mute-tooltip .tooltip-anchor {
  opacity: 0;
}

.drift-video .video-js .vjs-big-play-button {
  top: calc(50% - 14px);
  left: calc(50% + 6px);
}

.drift-video .video-js .vjs-big-play-button {
  height: 3em;
  width: 3em;
  background-repeat: no-repeat;
  border: none !important;
  filter: drop-shadow(0px 2px 2px rgba(70, 82, 89, 0.3));
  cursor: pointer;
  background-position: center;
  background-color: unset;
  transition: all 0.4s;
  background-image: url('https://static.hyfy.io/images/Desktop_play_button.svg');
}

.drift-video .video-js:hover .vjs-big-play-button,
.drift-video .video-js .vjs-big-play-button:focus {
  background-color: unset;
  outline: none;
}

.drift-video .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: unset;
}

.drift-video .video-js:hover .vjs-big-play-button,
.drift-video .video-js .vjs-big-play-button:focus {
  background-image: url('https://static.hyfy.io/images/Desktop_play_button_hover.svg');
}

/* DEFAULT END OF VIDEO CTA STYLES */
.embedded-player-start-overlay,
.end-video-cta-container {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  font-family: 'ProximaNova-Semibold';
  font-size: 20px;
  z-index: 10;
}

.embedded-player-start-overlay {
  background: rgba(0, 0, 0, 0.2);
}

.end-video-cta-container .video-overlay-content,
.embedded-player-start-overlay .video-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.end-video-cta-container .end-video-CTA-icon {
  margin-bottom: 5%;
  width: 140px;
  filter: invert(1);
}

.end-video-cta-container .end-video-CTA-copy {
  margin-bottom: 4%;
  width: 85%;
  max-width: 500px;
  line-height: 28px;
  display: block;
  text-align: center;
}

.end-video-cta-container #end-video-CTA-replay {
  width: 65%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.end-video-cta-container .end-video-CTA-replay-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.end-video-cta-container .replay-icon {
  width: 13px;
  height: 13px;
  margin-right: 8px;
  margin-bottom: 2px;
}

.drift-video.mobile .end-video-cta-container .end-video-CTA-icon {
  display: none;
}

.drift-video.mobile .end-video-cta-container .end-video-CTA-copy {
  font-size: 12px;
}

.drift-video.mobile .end-video-cta-container #end-video-CTA-replay {
  margin-top: 0px;
}

.drift-video.mobile .end-video-cta-container .end-video-CTA-replay-copy {
  font-size: 18px;
}

/* Embedded player overrides + custom styling */
.drift-video .embedded-player .video-js > .vjs-big-play-button {
  display: none;
}

.drift-video .embedded-player .video-js .embedded-player-start-overlay .vjs-big-play-button {
  top: calc(50% - 30px);
}

.drift-video .embedded-player .end-video-cta-container #end-video-CTA-replay {
  margin-top: 0;
}

.drift-video .embedded-player .end-video-cta-container .end-video-CTA-replay-copy {
  font-size: 21px;
}

.drift-video .embedded-player .end-video-cta-container .replay-icon {
  width: 18px;
  height: 18px;
}

.drift-video .video-data-overlay-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  box-sizing: border-box;
}

.drift-video .video-data-overlay-container .video-data-overlay-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.drift-video .video-data-overlay-container .video-data-overlay-header .video-data-overlay-title {
  margin: 0;
}

.drift-video .video-data-overlay-container .video-data-overlay-creator-info {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.drift-video
  .video-data-overlay-container
  .video-data-overlay-creator-info
  .video-data-overlay-avatar {
  width: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.drift-video
  .video-data-overlay-container
  .video-data-overlay-creator-info
  .video-data-overlay-name {
  font-size: 16px;
  margin-right: 8px;
}

.drift-video
  .video-data-overlay-container
  .video-data-overlay-creator-info
  .video-data-overlay-button {
  font-size: 12px;
  padding: 4px 8px;
  background: white;
  color: black;
  text-decoration: none;
  border: none;
  border-radius: 3px;
}
