.folder-node {
  display: flex;
  border: 1px solid transparent;
}

.folder-node--dragging {
  opacity: 0.5;
}

.folder-node__content {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  position: relative;
}

.folder-node .tc-subnav-link {
  padding: 0 !important;
  -webkit-user-drag: none;
  width: 100%;
}

.folder-node .tc-subnav-link .folder-icon {
  position: relative;
  top: 1px;
  margin-right: 7px;
}

.folder-node--drop-hover .tc-subnav-link,
.folder-node--drop-hover .tc-subnav-link:hover {
  color: black !important;
  transition: 0s;
}

.folder-node--drop-hover svg path {
  stroke: black;
}

.tc-subnav .folder-node--drop-hover .tc-subnav-link.tc-subnav-link--active {
  box-shadow: 0 0 0 1px var(--color-p3-4);
}

.folder-node a.tc-subnav-link--active .folder-icon path {
  fill: white !important;
}

.folder-node:not(.folder-node--drop-hover) a:not(.tc-subnav-link--active) svg path {
  fill: transparent;
  stroke: #9eabb3;
}

.folder-node .tc-subnav-link .expand-collapse-button svg {
  margin: 0;
  transform: rotate(180deg);
  transition-duration: 0.2s;
}

.folder-node .tc-subnav-link .expand-collapse-button--expanded svg {
  transform: rotate(270deg);
}

.folder-node__spacer {
  height: 100%;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  min-width: 8px;
}

.folder-node__title {
  padding-left: 4px;
  padding-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  flex-grow: 1;
}

.folder-tree div:first-child .folder-node__title {
  font-family: var(--main-font-family--bold);
  font-size: var(--font-size-subtle);
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.folder-tree div:first-child .tc-subnav-link:not(.tc-subnav-link--active) .folder-node__title {
  color: var(--color-p1);
}
