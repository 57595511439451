.viewing-own-video-header {
  height: 48px;
  background: var(--color-p1-3);
  display: flex;
  padding: 0 40px;
  flex-direction: row;
}

.viewing-own-video-header .own-video-helper-text {
  line-height: 50px;
  margin-left: 24px;
}

.viewing-own-video-header .back-to-share-button {
  height: 32px;
  margin-top: 8px;
}

.share-video-page .viewing-own-video-header {
  padding: 0 6px;
}
