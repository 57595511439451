.video-library-table {
  flex-grow: 1;
}

.video-library-table table {
  border-collapse: separate;
}

.video-library-table .tide-table-column-header--sortable > span::after,
.video-library-table .tide-table-column-header--sorting-asc > span::after {
  top: 4px;
}

.video-library-table th:not(.tide-table-column-header--sortable) {
  cursor: not-allowed;
}

.video-library-table tbody td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.video-library-row__thumbnail-and-title {
  display: flex;
  align-items: center;
}

.video-library-row__title {
  margin-left: 16px;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: var(--color-p1-10);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}

.video-library-row__creator {
  margin-left: 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: lightslategray;
}

.video-library-row__thumbnail {
  position: relative;
  background-color: black;
  height: 48px;
  width: 85px;
  border-radius: 3px;
}

.video-library-row__source-type {
  position: absolute;
  top: 39px;
  right: -7px;
  background-color: var(--color-p1-10);
  color: var(--color-p1-2);
  text-transform: uppercase;
  font-size: 9px;
  line-height: 9px;
  padding: 4px 2px 3px 2px;
  min-width: 22px;
  text-align: center;
  border-radius: 2px;
}

.video-library-table img {
  height: 48px;
  margin: auto;
  display: block;
  max-width: 85px;
  object-fit: cover;
  border-radius: 3px;
}

.video-library-table__dragging-row {
  opacity: 0.5;
}

.video-library__folder-drop-target {
  padding: 8px 16px;
  margin: -8px -16px;
  border-radius: 4px;
}

.video-library-table .video-library__folder-drop-target--active td {
  background-color: var(--color-p3-2);
  border-top: 1px solid var(--color-p3-6);
  border-bottom: 1px solid var(--color-p3-6);
  padding-top: 7px;
}

.video-library-table .video-library__folder-drop-target--active td:first-child {
  border-left: 1px solid var(--color-p3-6);
}

.video-library-table .video-library__folder-drop-target--active td:last-child {
  border-right: 1px solid var(--color-p3-6);
}

.video-library__folder-drop-target--active .video-library-row__title {
  color: var(--color-p3-6);
}

.video-library-row__duration-viewed-plus-actions {
  display: flex;
  align-items: center;
}

.video-library-row__duration_viewed {
  flex-grow: 1;
}

.video-library-row__cloning-users {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.video-library-row__cloning-users-empty-state {
  color: var(--color-p1-5-5);
}

.cloning-user-2 {
  position: relative;
  left: -16px;
}

.cloning-user-3 {
  position: relative;
  left: -30px;
}

.cloning-user-overflow {
  position: relative;
  left: -14px;
}

.inline-copy-gif-button,
.inline-copy-link-and-gif-button {
  visibility: hidden;
}

.inline-gif-action-dropdown-button,
.inline-video-action-dropdown-button {
  visibility: hidden;
}

.inline-clone-button {
  visibility: hidden;
}

.video-library-table .tide-action-dropdown-actions {
  z-index: 1;
}

.video-library-table tr:hover button {
  visibility: visible;
}

.video-library-table tr:not(:hover) button {
  transition: none;
}
