.inline-video-action-dropdown .tide-action-dropdown-actions {
  border: none;
  box-shadow: var(--box-shadow-1);
  width: 208px;
}

.inline-video-action-dropdown
  .tide-action-dropdown-actions
  .tide-action-dropdown-action:last-child {
  color: var(--color-s3-6);
}
