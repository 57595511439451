.expand-collapse-button {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: transform var(--default-subnav-transition);
  min-height: 26px;
  min-width: 26px;
}

.expand-collapse-button svg path {
  fill: var(--color-p1-5);
}

.expand-collapse-button:focus {
  outline: none;
}

.expand-collapse-button--keyboard-focus:focus {
  outline-color: var(--color-p3-6);
  outline-style: auto;
  outline-width: 5px;
}

.expand-collapse-button:disabled {
  visibility: hidden;
}
