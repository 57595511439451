.add-to-folder .folder-list-container {
  width: 100%;
  height: 400px;
  overflow: scroll;
}

.add-to-folder .tide-heading:first-child {
  margin: 0;
}

.add-to-folder .selection-block {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-to-folder__option-label {
  margin-left: 8px;
  font-family: var(--main-font-family--semibold);
}
